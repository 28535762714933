/* @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap'); */

:root {
	--gray5-color: #494848;
	--gray4-color: #636363;
	/* --gray3-color: #909090;  */
	--gray2-color: #b4b4b4;
	/* --gray1-color: #D4D4D4; */
	--background-color: #1a0083;
	--container-color: #00acdf;

	--footer-color: #12005b;

	--large-radius: 25px;
	--medium-radius: 15px;
	--small-radius: 10px;
}

body {
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	/* font-family: 'Varela Round', sans-serif; */
}

/* navigation */

.nav-container {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	/* background-color: salmon; */
}

nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: calc(100% - 30%);
	padding-left: 7.5%;
	padding-right: 7.5%; */
	height: 100%;
	color: var(--gray4-color);
	/* max-width: 1200px; */
}

@media only screen and (max-width: 479px) {
	nav {
		width: 100%;
		padding-left: 15%;
		padding-right: 15%;
	}

	.nav-resume {
		display: none;
	}
}

@media only screen and (min-width: 480px) and (max-width: 1023px) {
	nav {
		width: 385px;
	}
}

@media only screen and (min-width: 1024px) {
	nav {
		width: 455px;
	}
}

.nav-link {
	text-decoration: none;
	display: inline;
	/* font-weight: 700; */
	font-size: large;
	cursor: pointer;
}

.nav-link:hover {
	transition: transform 0.3s ease;
	transform: scale(1.1);
}

.nav-resume:hover {
	transition: transform 0.3s ease;
	transform: scale(1.1);
}

/* scroll up */
.scroll-button {
	position: fixed;
	bottom: calc(max(20px, 110px));
	right: 2%;

	color: white;
	background-color: transparent;
	border: 2px solid white;
	padding: 10px;
	border-radius: 50%;

	cursor: pointer;
	z-index: 9999;

	user-select: none;

	transition: transform 0.3s ease;
}

.scroll-button:hover {
	transform: scale(1.1);
}

/* footer */

footer {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: var(--footer-color);
	height: 50px;

	position: relative;
}

.scroll-container {
	position: absolute;
	z-index: 1;

	width: 100%;
	height: 100%;
	display: flex;
	justify-content: right;
	align-items: center;

	/* background-color: red; */
	width: calc(100% - 15%);
	padding-right: 10px;
	max-width: 1200px;
}

.scroll-btn {
	/* background-color: red; */
	height: 80%;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	user-select: none;
}

.scroll-btn i {
	transition: font-size 0.3s ease;
	font-size: 35px;
}

.scroll-btn:hover i {
	font-size: 40px;
}

/* home */

#home-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.home-top {
	display: flex;
	flex-direction: column;
	height: 25%;
	width: 100%;
}

.home-bot {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 75%;
	width: 100%;
}

@media only screen and (max-width: 479px) {
	.home-top {
		height: 20%;
	}

	.home-bot {
		height: 80%;
	}

	.title-name {
		display: block;
		text-align: center;
		width: 250px;
	}

	.title-name {
		font-size: 100px;
		font-weight: 800;
	}

	.title-position {
		display: none;
	}
}

@media only screen and (min-width: 480px) and (max-width: 1023px) {
	.title-name {
		font-size: 85px;
		font-weight: 800;
	}

	.title-position {
		font-size: 27px;
		font-weight: 600;
		color: var(--gray5-color);
	}
}

@media only screen and (min-width: 1024px) {
	.title-name {
		font-size: 100px;
		font-weight: 900;
	}

	.title-position {
		font-size: 32px;
		font-weight: 600;
		color: var(--gray5-color);
	}
}

.title-resume {
	font-size: 20px;
	margin: 5px;
	padding: 5px 25px 5px 25px;
}

/* parallax */

.parallax-container {
	height: 1000px;
	position: relative;
	overflow: hidden;
}

.animation-layer {
	background-position: top center;
	background-size: auto 1000px;
	background-repeat: repeat-x;
	height: 1000px;
	width: 100%;
	position: fixed;
}

/* images */

#wave1 {
	background-image: url(./images/wave1.png);
}

#wave2 {
	background-image: url(./images/wave2.png);
}

#wave3 {
	background-image: url(./images/wave3.png);
}

/* Pages */

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--background-color);
	height: 800px;
	width: 100%;
	/* padding-top: 40px;
  padding-bottom: 40px; */
}

.box {
	width: calc(100% - 15%); 
	/* width: calc(100% - 10%);  */
	/* width: calc(100% - 5%); */
	max-width: 1200px;
	/* height: calc(100% - 25%);  */
	height: calc(100% - 10%);
	/* background-color: #014F86;  */
	/* border-radius: 50px; */

	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: center;

	color: white;
}

.heading {
	width: 100%;
	height: 72px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 48px;
	font-weight: 900;
	/* background-color: salmon; */
}

.small-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--background-color);
	height: 550px;
	width: 100%;

	padding-top: 40px;
	padding-bottom: 40px;
}

.small-box {
	width: calc(100% - 15%); 
	/* width: calc(100% - 10%);  */
	/* width: calc(100% - 5%); */
	max-width: 1200px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: center;

	color: white;
}

.body {
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
}

/* about */

.about-container {
	display: flex;
}

.description-area {
	display: flex;
	flex-direction: column;
	flex: 2;
}

.description-text {
	height: calc(5 / 6 * 100% - 20px);
	background-color: var(--container-color);
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: var(--large-radius);
	box-sizing: border-box;

	text-align: justify;
}

@media only screen and (max-width: 479px) {
	.description-text {
		padding: 30px;
		font-size: 18px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.description-text {
		padding: 45px;
		font-size: 20px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.description-text {
		padding: 60px;
		font-size: 19.75px;
	}
}

@media only screen and (min-width: 1024px) {
	.description-text {
		padding: 75px;
		font-size: 22px;
	}
}

.description-resume {
	flex: 1;
	/* height: calc(1/6*100%); */
	background-color: var(--container-color);
	margin: 10px;
	padding: px;
	border-radius: var(--large-radius);

	display: flex;
	justify-content: center;
	align-items: center;
}

.download-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	gap: 2.5px;
	color: white;

	cursor: pointer;

	background-color: transparent;
	border: none;
	transition: all 0.3s ease;
}

.download-btn:hover {
	color: var(--gray2-color);
}

/* .icon-container {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
} */

.icon-btn {
	background-color: transparent;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 767px) {
	.about-container .icons-container {
		display: none;
	}
}

.icon-block {
	background-color: var(--container-color);
	flex: 1;
	margin: 10px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: var(--large-radius);
}

.icons-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.about-icon {
	width: 75px;
	height: 75px;
}

.text {
	color: white;
	text-decoration: none;
	font-size: 24px;
	font-weight: 700;
}

/* skills */

.skills-container {
	display: flex;
	justify-content: space-between;
}

.skill-area {
	display: flex;
	justify-content: center;
	text-align: center;

	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 10px;
	border-bottom: 2px solid white;

	font-size: 20px;
	font-weight: 700;
}

.technical-area {
	width: 100%;
	height: 100%;
	background-color: var(--container-color);
	margin: 10px;
	padding: 5px;
	border-radius: var(--large-radius);
}

.languages,
.technologies,
.tools {
	flex: 1;
	background-color: var(--container-color);
	margin: 10px;
	padding: 5px;
	border-radius: var(--large-radius);
}

/* item */

/* New */

.grid-item {
	flex: 0 0 auto;
	aspect-ratio: 1;
	margin: 5px;
	padding: 5px;
	background: white;
	box-sizing: border-box;
	border-radius: 15px;
	/* transition: transform 0.3s ease; */
}

.grid-item:hover {
	transform: scale(1.025);
}

.item-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	aspect-ratio: 1;
	color: var(--gray5-color);
}


.skill-icon {
	fill: var(--gray5-color);
	flex: 1;
}

/* Old */

.sortable-item {
	aspect-ratio: 1/1;
	margin: 5px;
	padding: 5px;
	background-color: white;
	border-radius: var(--medium-radius);
}

@media (pointer: fine) {
	.sortable-item {
		cursor: grab;
		pointer-events: auto;
	}
}

@media (pointer: coarse) {
	.sortable-item {
		cursor: default;
		pointer-events: none;
	}
}

/* .item-content {
	display: flex;
} */

.overlay-item {
	display: flex;
	width: calc(100% - 10px);
	aspect-ratio: 1/1;
	padding: 5px;
	background-color: white;
	cursor: grabbing;
	border-radius: var(--medium-radius);
}

/* projects */

.projects-container {
	display: flex;
	align-items: flex-end;
	padding: 10px;
	box-sizing: border-box;
}

/* cards */

.card {
	height: 498px;
	/* border-radius: 25px; */
	background-color: white;
	margin: 10px;
	padding: 20px;
	border-radius: var(--large-radius);
}

.card-top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50%;
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 125px;
	aspect-ratio: 1;

	border-radius: 50%;
	border: 2px solid var(--gray2-color);
}

.logo {
	color: var(--gray5-color);
	font-size: 75px;
}

.card-bot {
	display: flex;
	flex-direction: column;
	height: 50%;
}

.name,
.type,
.tech-container,
.project-btn-container {
	display: flex;
	justify-content: center;
}

.name {
	font-size: 24px;
	color: var(--gray5-color);
	text-align: center;
}

.type {
	font-size: 16px;
	color: var(--gray4-color);
	text-align: center;
}

.type,
.tech-container {
	padding-bottom: 5%;
	margin-bottom: 5%;
	border-bottom: 2px solid transparent;
	border-image: linear-gradient(
		to right,
		transparent 40%,
		var(--gray2-color) 40%,
		var(--gray2-color) 60%,
		transparent 60%
	);
	border-image-slice: 1;
}

.tech-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}

.tech-btn {
	border: 2px solid var(--gray2-color);
	/* font-size: 16px; */
	color: var(--gray4-color);
	padding: 4px 8px;
	background-color: transparent;
	/* border-radius: 5px; */
	/* margin: 14px; */
	cursor: pointer;
	transition: all 0.3s ease;
	border-radius: var(--small-radius);
	/* transition: transform 0.3s ease; */
}
.tech-btn:hover {
	background-color: var(--gray2-color);
	color: white;
	transform: scale(1.1);
}

.project-btn {
	height: 50px;
	width: 50px;
	border: none;
	margin: 5px;

	background-color: transparent;
	cursor: pointer;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	/* transition: all 0.3s ease; */
}

.project-btn:hover {
	transform: scale(1.1);
}

/* Swipper */

.swiper {
	background-color: var(--container-color);
	border-radius: var(--large-radius);
	/* height: 100%; */
}

.swiper-nav-btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: pink; */
}

@media only screen and (max-width: 767px) {
	.prev-btn-container,
	.next-btn-container {
		flex: 1;
		text-align: center;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.prev-btn-container,
	.next-btn-container {
		flex: 1;
		text-align: center;
	}
}

@media only screen and (min-width: 1024px) {
	.prev-btn-container,
	.next-btn-container,
	.pagination-btn {
		flex: 1;
		text-align: center;
	}
}

.swiper-pagination {
	bottom: unset !important;
}

.pagination-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.swipe-btn {
	border: none;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin: 10px;
	cursor: pointer;
	color: var(--gray4-color);
	
	background-color: white;
	
	transition: all 0.3s ease;
}

.swipe-btn:hover {
	background-color: var(--gray2-color);
	color: white;
}

.swipe-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 35px;
}

.swiper-pagination-bullet {
	--swiper-pagination-color: white;
}

/* contact */

.icon-btn {
	height: 100%;
}

.contact-icon, .link {
  transition: fill 0.3s ease, color 0.3s ease; 
}

.icon-btn:hover .contact-icon {
  fill: var(--gray2-color);
}

.icon-btn:hover .link {
  color: var(--gray2-color);
}

.contact-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 479px) {
	.contact-container {
		display: flex;
		flex-direction: column;
	}

	.contact-area {
		display: flex;
		flex-direction: row;
		flex: 1;
	}

	.contact-icon {
		/* width: 60%;
		height: 60%; */
		width: 40px;
		height: 40px;
	}

	.link {
		/* font-size: 16px; */
		display: none;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.contact-container {
		display: flex;
		flex-direction: column;
	}

	.contact-area {
		display: flex;
		flex-direction: row;
		flex: 1;
	}

	.contact-icon {
		width: 65px;
		height: 65px;
	}

	.link {
		font-size: 20px;
		font-weight: 500;
	}
}

@media only screen and (min-width: 768px) {
	.contact-container {
		display: flex;
	}

	.contact-area {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.contact-icon {
		width: 75px;
		height: 75px;
	}

	.link {
		font-size: 24px;
		font-weight: 700;
	}
}

/* contact form */

.contact-form {
	display: flex;
	flex-direction: column;
	background-color: var(--container-color);
	margin: 10px;
	padding: 10px;
	flex: 2;

	border-radius: var(--large-radius);
}

@media (max-width: 479px) {
	.contact-form {
			flex: 4; 
	}
}

@media only screen and (max-width: 767px) {
	.name-email-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
	}

	.name-container,
	.email-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 30px;
	}

	.name-container {
		margin-bottom: 5px;
	}
	.email-container {
		margin-top: 5px;
	}

	.name-input,
	.email-input {
		flex: 1;
		padding: 10px;
		border: none;
		outline: none;
		border-radius: var(--large-radius);
	}

	.message-container {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	textarea {
		resize: none;
		padding: 10px;
		flex: 1;
		border: none;
		outline: none;
		border-radius: var(--large-radius);
	}
}

@media only screen and (min-width: 768px) {
	.name-email-container {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;
	}

	.name-container,
	.email-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 60px;
	}

	.name-container {
		margin-right: 5px;
	}
	.email-container {
		margin-left: 5px;
	}

	.name-input,
	.email-input {
		flex: 1;
		padding: 20px;
		border: none;
		outline: none;
		border-radius: var(--large-radius);
		width: calc(100% - 40px);
	}

	.message-container {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	textarea {
		resize: none;
		padding: 20px;
		flex: 1;
		border: none;
		outline: none;
		border-radius: var(--large-radius);
	}
}

@media only screen and (max-width: 767px) {
	.submit-btn {
		height: 30px;
		margin-top: 10px;
		border: none;
		background-color: white;
		color: var(--gray4-color);
		cursor: pointer;
		border-radius: var(--large-radius);
		transition: all 0.3s ease;
	}
}

@media only screen and (min-width: 768px) {
	.submit-btn {
		height: 60px;
		margin-top: 10px;
		border: none;
		background-color: white;
		color: var(--gray4-color);
		cursor: pointer;
		border-radius: var(--large-radius);
		transition: all 0.3s ease;
	}
}

.submit-btn:hover, .submitting {
	background-color: var(--gray2-color);
	color: white;
}

